var texts = {
	english: {
		menu: {
			home: 'Inicio',
			who: 'Transportes',
			manuServ: 'Reciclaje',
			comServ: 'Confinamiento',
			faq: 'Preguntas frecuentes',
			contact: 'Contacto'
		},
		home: {
			heroHeader: 'Bienvenido',
			heroSubheader:'At stc we generate all-in-one solutions for our clients. One service, multiple processes, one provider.',
			headerBtn: 'know us',
			subtitle: 'In addition to being pioneers in Zamak injection processes. We are also the largest producer of hinges in the country',
			companyYears: 'MORE THAN 70 YEARS OF EXPERIENCE GIVING DIE-CUTTING, FINISHING AND PAINTING SERVICES',
			manuTitle: 'MANUFACTURING SERVICES',
			comerTitle: 'COMMERCIAL SERVICES',
			facilitiesBack: 'FACILITIES',
			facilitiesTitle: 'OUR FACILITIES',
			facilitiesDesc:
				'Manufacturing Area: 230,000 ft2 (35,000 ft2 free) Finished Goods Warehouse and Distribution Center Area: 40,000 ft2 Total Land: 500,000 ft2 or 11.4 Acres (80,000 ft2 available for new construction).'
		},
		footer: {
			address:'Ave. Nogalar Sur 301 Colonia Industrial Nogalar, Antiguo Nogalar, 66484 San Nicolás de los Garza, N.L.',
			mail: 'contact@stc.com',
			phone: '+52 81 8262 5900',
			home: 'Home',
			who: 'About us?',
			manuServ: 'Manufacturing services',
			comServ: 'Commercial services',
			contact: 'Contact',
			copyright: '© Copyright stc 2020'
		},
		who: {
			title: '¿Quiénes somos?',
			qualityBack: 'CALIDAD',
			qualityTitle: 'POLÍTICA DE CALIDAD',
			qualityDesc:'En stc está nuestro compromiso con la fabricación e importación de cerraduras, candados, bisagras y otros productos de ferretería, así como servicios de enchapado, fundición, conformado, ensamblaje, empaque y prueba de metales; que cumplan y satisfagan los requisitos de nuestros grupos de interés, incluidos los requisitos legales y reglamentarios. Esto se logra, mediante el cumplimiento y la mejora continua de nuestro sistema de gestión de calidad, incluidos los objetivos estratégicos de calidad.',
			historyTitle: 'Our History',
			historyYears: [
				{
					year: '1947',
					description: 'Fanal begins operations',
					image: '/static/fanal.jpeg'
				},
				{
					year: '2000',
					description: 'National Manufacturing Company acquires Fanal & Mont hard',
					image: '/static/National.png'
				},
				{
					year: '2005',
					description: 'Stanley acquires National Corp.',
					image: '/static/Stanley.png'
				},
				{
					year: '2009',
					description: 'Merge to create Stanley Black and Decker',
					image: '/static/Marge.png'
				},
				{
					year: '2012',
					description: 'Spectrum Brands acquires HHI Group',
					image: '/static/Spectrum.png'
				},
				{
					year: '2015',
					description: 'stc acquires HHI Monterrey Operation',
					image: '/static/Imagen_1.png'
				}
			],
			misionBack: 'MISSION',
			misionTitle: 'OUR MISSION IS ...',
			misionDesc:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ipsum sem, commodo vel ligula sed, cursus porttitor neque. Duis laoreet lectus in enim finibus, tempor dignissim sapien efficitur.',
			visionBack: 'VISION',
			visionTitle: 'OUR VISION IS ...',
			visionDesc:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ipsum sem, commodo vel ligula sed, cursus porttitor neque. Duis laoreet lectus in enim finibus, tempor dignissim sapien efficitur.',
			valuesTitle: 'INDUSTRIES WE SERVE'
		}
	},
	spanish: {
		menu: {
			home: 'INICIO',
			who: '¿QUIÉNES SOMOS?',
			manuServ: 'SERVICIOS DE MANUFACTURA',
			comServ: 'SERVICIOS COMERCIALES',
			faq: 'Preguntas frecuentes',
			contact: 'CONTACTO'
		},
		home: {
			heroHeader: 'Lorem ipsum dolor sit amet',
			heroSubheader:
				'En stc generamos soluciones todo en uno para nuestros clientes. Un servicio, múltiples procesos , un proveedor.',
			headerBtn: 'Conócenos',
			subtitle:
				'Además de ser pioneros en procesos de inyección de Zamak. Así mismo somos el mayor productor de bisagras del país',
			companyYears: 'MÁS DE 70 AÑOS DE EXPERIENCIA DANDO SERVICIOS DE TROQUELADO, ACABADOS Y PINTURA',
			manuTitle: 'SERVICIOS DE MANUFACTURA',
			comerTitle: 'SERVICIOS COMERCIALES',
			facilitiesBack: 'FACILITIES',
			facilitiesTitle: 'OUR FACILITIES',
			facilitiesDesc:
				'Manufacturing Area: 230,000 ft2 (35,000 ft2 free) Finished Goods Warehouse and Distribution Center Area: 40,000 ft2 Total Land: 500,000 ft2 or 11.4 Acres (80,000 ft2 available for new construction).'
		},
		footer: {
			address:
				'Ave. Nogalar Sur 301 Colonia Industrial Nogalar, Antiguo Nogalar, 66484 San Nicolás de los Garza, N.L.',
			mail: 'contact@stc.com',
			phone: '+52 81 8262 5900',
			home: 'Inicio',
			who: '¿Quiénes somos?',
			manuServ: 'Servicios de manufactura',
			comServ: 'Servicios comerciales',
			contact: 'Contacto',
			copyright: '© Copyright stc 2020'
		},
		who: {
			title: '¿Quiénes somos?',
			qualityBack: 'CALIDAD',
			qualityTitle: 'POLÍTICA DE CALIDAD',
			qualityDesc:
				'En stc está nuestro compromiso con la fabricación e importación de cerraduras, candados, bisagras y otros productos de ferretería, así como servicios de enchapado, fundición, conformado, ensamblaje, empaque y prueba de metales; que cumplan y satisfagan los requisitos de nuestros grupos de interés, incluidos los requisitos legales y reglamentarios. Esto se logra, mediante el cumplimiento y la mejora continua de nuestro sistema de gestión de calidad, incluidos los objetivos estratégicos de calidad.',
			historyTitle: 'Nuestra historia',
			historyYears: [
				{
					year: '1947',
					description: 'Fanal inicia operaciones',
					image: '/static/fanal.jpeg'
				},
				{
					year: '2000',
					description: 'National Manufacturing Company adquiere Fanal & Mont hard',
					image: '/static/National.png'
				},
				{
					year: '2005',
					description: 'Stanley adquiere National Corp.',
					image: '/static/Stanley.png'
				},
				{
					year: '2009',
					description: 'Fusión Stanley Black and Decker',
					image: '/static/Marge.png'
				},
				{
					year: '2012',
					description: 'Spectrum Brands adquiere HHI Group',
					image: '/static/Spectrum.png'
				},
				{
					year: '2015',
					description: 'stc adquiere HHI Monterrey Operation',
					image: '/static/Imagen_1.png'
				}
			],
			misionBack: 'MISIÓN',
			misionTitle: 'NUESTRA MISIÓN ES…',
			misionDesc:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ipsum sem, commodo vel ligula sed, cursus porttitor neque. Duis laoreet lectus in enim finibus, tempor dignissim sapien efficitur.',
			visionBack: 'VISIÓN',
			visionTitle: 'NUESTRA VISIÓN ES…',
			visionDesc:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ipsum sem, commodo vel ligula sed, cursus porttitor neque. Duis laoreet lectus in enim finibus, tempor dignissim sapien efficitur.',
			valuesTitle: 'NUESTRAS INDUSTRIAS'
		}
	}
};

let that = this


var axios = require('axios');




export default texts;
